<template>
  <v-dialog
    v-model="viewModal"
    max-width="600px"
    persistent
    scrollable
    content-class="admin-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="second"
        class="mb-1 white--text"
        v-bind="attrs"
        v-on="on"
        @click="btnDialog()"
      >
        Registrar
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="card-title">
        <span class="headline">{{ edit ? "Editar" : "Registrar" }}</span>

        <v-spacer></v-spacer>

        <CustomButtonAction
          :btnApply="true"
          :btnCancel="true"
          :loaderApply="loading"
          colorBtnApply="white"
          colorBtnCancel="white"
          @onChangeApply="apply()"
          @onChangeCancel="cancel()"
        />
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="6" align-self="end">
                <v-text-field
                  label="Código"
                  v-model="item.code"
                  :autocomplete="'off'"
                  dense
                  disabled
                  color="second"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <div class="image-center">
                  <input
                    id="filePhoto"
                    type="file"
                    accept="image/jpeg,image/png,.jpeg,.jpg,.png"
                    style="display: none"
                    @change="processWebImage($event)"
                  />
                  <div class="select-image" v-if="!item.photo">
                    <label for="filePhoto" class="center">
                      <v-icon>mdi-tray-arrow-up</v-icon>
                      Seleccionar Imagen
                    </label>
                  </div>
                  <div class="file" v-if="item.photo">
                    <img
                      :src="
                        item.photo.indexOf('/images') !== -1
                          ? url + item.photo
                          : item.photo
                      "
                    />
                    <div class="btn-delete">
                      <v-btn color="error" small fab @click="deletePhoto()">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre"
                  v-model="item.name"
                  :autocomplete="'off'"
                  :rules="requiredRules"
                  :maxlength="500"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  :autocomplete="'off'"
                  label="Descripción"
                  v-model="item.description"
                  :rules="requiredRules"
                  :maxlength="1000"
                  dense
                  rows="3"
                  color="second"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  label="Seleccionar Marca"
                  :items="listMark"
                  :rules="requiredRules"
                  item-text="name"
                  item-value="_id"
                  v-model="item.mark"
                  dense
                  color="second"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Precio"
                  type="number"
                  v-model="item.price"
                  :autocomplete="'off'"
                  :rules="numberRules"
                  dense
                  color="second"
                  prefix="$"
                  step=".01"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Cantidad"
                  type="number"
                  v-model="item.stock"
                  :autocomplete="'off'"
                  :rules="numberRules"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Cantidad mínima"
                  type="number"
                  v-model="item.minStock"
                  :autocomplete="'off'"
                  :rules="numberRules"
                  dense
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <CustomCrop
      :dialog.sync="modalCrop"
      :imgSrc.sync="photo"
      @onChange="onChangeCrop($event)"
    />
  </v-dialog>
</template>

<script>
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomCrop from "../../../../shared/components/custom-crop/CustomCrop.vue";
import useValidate from "../../../../shared/validate/useValidate";
import ProductServices from "../../../../core/services/product-services";
import MarkServices from "../../../../core/services/mark-services";
import MarkStorage from "../../../../core/storage/mark-storage";

export default {
  name: "EditAdmin",
  data: () => ({
    url: `${process.env.VUE_APP_PHOTO_API}`,
    viewModal: false,
    modalCrop: false,
    valid: false,
    loading: false,
    item: { photo: "" },
    listMark: [],
    photo: "",
    requiredRules: [useValidate["required"]],
    numberRules: [useValidate["required"], useValidate["number"]],
  }),
  components: {
    CustomButtonAction,
    CustomCrop,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    data: {},
  },
  watch: {
    dialog: function (value) {
      this.viewModal = value;
    },
    edit: function (value) {
      if (value) {
        this.item = { ...this.data };
      }
    },
  },
  created() {
    this.markAll();
    this.getMarkAll();
  },
  methods: {
    btnDialog() {
      this.item = {};
      this.viewModal = true;
    },
    apply() {
      const validate = this.$refs.form.validate();
      if (validate) {
        if (this.edit) {
          this.btnEdit();
        } else {
          this.btnSave();
        }
      }
    },
    cancel() {
      this.viewModal = false;
      this.$emit("update:dialog", false);
      this.$emit("update:edit", false);
      setTimeout(() => {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }, 500);
    },
    btnSave() {
      this.loading = true;
      const { saveProduct } = ProductServices();
      this.item.available = true;
      this.item.status = 1;
      this.item.price = Number.parseFloat(
        this.item.price.replace(",", ".")
      ).toFixed(2);
      saveProduct(this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.$toast.success("El producto se ha registrado de forma exitosa");
          this.$emit("onChange", response.data);
          this.cancel();
        }
        this.loading = false;
      });
    },
    btnEdit() {
      this.loading = true;
      const { updateProduct } = ProductServices();
      this.item.price = Number.parseFloat(
        this.item.price.replace(",", ".")
      ).toFixed(2);
      updateProduct(this.item.id, this.item).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.$toast.success("El producto se ha actualizado de forma exitosa");
          this.$emit("onChange", response.data);
          this.cancel();
        }
        this.loading = false;
      });
    },
    processWebImage(event) {
      const reader = new FileReader();
      reader.onload = async (readerEvent) => {
        const image64 = readerEvent.target.result;
        this.modalCrop = true;
        this.photo = image64;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    onChangeCrop(event) {
      this.item.photo = event;
    },
    deletePhoto() {
      this.item = { ...this.item, photo: "" };
    },
    async markAll() {
      const { markAll } = MarkStorage();
      this.listMark = await markAll();
    },
    getMarkAll() {
      const { getMarkAll } = MarkServices();
      const { markAdd } = MarkStorage();
      getMarkAll().then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          response.data.map((item) => {
            markAdd(item);
          });
          this.markAll();
        }
      });
    },
  },
};
</script>
