<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: {
    chartData: {
      type: Array,
    },
    chartLabels: {
      type: Array,
    },
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    chartData: function (val) {
      if (val) {
        this.renderPieChart();
      }
    },
  },
  data() {
    return {
      options: {},
    };
  },
  mounted() {
    this.renderPieChart();
  },
  methods: {
    renderPieChart() {
      this.options = {
        responsive: true,
        title: {
          display: false,
          text: this.title,
          fontSize: 22,
        },
        legend: {
          display: true,
          position: "right",
          align: "center",
          fontFamily: "Allianz-Neo",
          textDirection: "ltr",
          labels: {
            usePointStyle: true,
            fontColor: "#000",
            padding: 15,
            fontSize: 12,
            generateLabels: (chart) => {
              const datasets = chart.data.datasets;
              if (datasets[0]) {
                return datasets[0].data.map((data, i) => ({
                  text: ` ${chart.data.labels[i]}: ${data}`,
                  fillStyle: datasets[0].backgroundColor[i],
                }));
              }
            },
          },
        },
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0.000001,
          },
          arc: {
            borderWidth: 5,
          },
        },
      };

      const chartData = {
        labels: this.chartLabels,
        datasets: [
          {
            backgroundColor: [
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
              "#" + Math.floor(Math.random() * 16777215).toString(16),
            ],
            data: this.chartData,
          },
        ],
      };

      this.renderChart(chartData, this.options);
    },
  },
};
</script>

<style lang="scss">
#pie-chart {
  width: 100% !important;
  height: 350px !important;
}
</style>
