const useLabel = {
  admin: {
    label: ["dni", "name", "lastName", "email", "phone", "createdAt"],
    value: {
      dni: "Cédula",
      name: "Nombre",
      lastName: "Apellido",
      email: "Correo electrónico",
      phone: "Teléfono",
      createdAt: "Fecha de creación",
    },
  },
  seller: {
    label: ["photo", "dni", "name", "lastName", "email", "phone", "createdAt"],
    value: {
      dni: "Cédula",
      name: "Nombre",
      lastName: "Apellido",
      email: "Correo electrónico",
      phone: "Teléfono",
      createdAt: "Fecha de creación",
    },
  },
  client: {
    label: [
      "rif",
      "businessName",
      "contact",
      "codeSICM",
      "segment",
      "email",
      "phone",
      "address",
      "seller",
      "createdAt",
    ],
    value: {
      rif: "Rif",
      businessName: "Razón Social",
      contact: "Contacto",
      codeSICM: "SICM / SADA",
      segment: "Segmento",
      email: "Correo electrónico",
      phone: "Teléfono",
      address: "Dirección",
      seller: "Vendedor",
      createdAt: "Fecha de creación",
    },
  },
  product: {
    label: [
      "photo",
      "code",
      "name",
      "description",
      "mark",
      "price",
      "stock",
      "minStock",
      "createdAt",
    ],
    value: {
      code: "Código",
      name: "Nombre",
      description: "Descripción",
      mark: "Marca",
      price: "Precio",
      stock: "Cantidad",
      minStock: "Cantidad mínima",
      createdAt: "Fecha de creación",
    },
  },
};

export default useLabel;
