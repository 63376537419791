<template>
  <div class="product page-list">
    <v-card>
      <v-card-title> <h1>Productos</h1> </v-card-title>
      <v-data-table
        :mobile-breakpoint="768"
        :headers="tableHeaders"
        :items="listData"
        :search="search"
        :loading="loaderTable"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100, -1],
        }"
        loading-text="Cargando... Por favor espere."
      >
        <template v-slot:[`item.photo`]="{ item }">
          <img class="profile" :src="item.photo | urlImage" alt="profile" />
        </template>

        <template v-slot:[`item.mark`]="{ item }">
          {{ item.mark.name }}
        </template>

        <template v-slot:[`item.available`]="{ item }">
          <v-switch
            class="switch"
            v-model="item.available"
            @change="toggleStatus(item)"
          ></v-switch>
        </template>

        <template v-slot:[`item.actions`]="{ item, index }">
          <CustomButtonAction
            :value="item"
            :btnDetail="true"
            :btnEdit="true"
            :btnDelete="true"
            :index="index"
            :loaderDelete="loaderDelete"
            @onChangeDetail="detailItem($event)"
            @onChangeEdit="editItem($event)"
            @onChangeDelete="deleteItem($event, index)"
          />
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="11" md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar ..."
                  single-line
                  hide-details
                  outlined
                  dense
                  clearable
                  color="second"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-spacer></v-spacer>

            <EditProduct
              :dialog.sync="editModal"
              :edit.sync="editView"
              :data="selectItem"
              @onChange="firstRowData($event)"
            />

            <CustomDetails
              :dialog.sync="detailModal"
              :item="selectItem"
              :type="'product'"
            />

            <CustomDialog
              :dialog.sync="deleteModal"
              :title="'Confirmar eliminación'"
              :message="'¿Estás seguro que quieres eliminar el registro?'"
              :maxWidth="'320'"
              @onChange="btnDelete()"
            ></CustomDialog>
          </v-toolbar>
          <v-divider></v-divider>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import useColumns from "./useColumns";
import EditProduct from "./edit-product.vue";
import ProductServices from "../../../../core/services/product-services";
import ProductStorage from "../../../../core/storage/product-storage";
import CustomButtonAction from "../../../../shared/components/custom-button-action/CustomButtonAction.vue";
import CustomDetails from "../../../../shared/components/custom-details/CustomDetails.vue";
import CustomDialog from "../../../../shared/components/custom-dialog/CustomDialog.vue";

export default {
  name: "Product",
  data: () => ({
    search: "",
    editView: false,
    editModal: false,
    detailModal: false,
    deleteModal: false,
    selectItem: {},
    listData: [],
    loaderTable: false,
    loaderDelete: {},
    selectIndex: null,
    tableHeaders: useColumns,
  }),
  components: {
    EditProduct,
    CustomDetails,
    CustomButtonAction,
    CustomDialog,
  },
  created() {
    this.getProduct();
    this.getAllProduct();
  },
  methods: {
    detailItem(item) {
      this.detailModal = true;
      this.selectItem = item;
    },
    editItem(item) {
      this.editModal = true;
      this.editView = true;
      this.selectItem = item;
    },
    deleteItem(item, index) {
      this.deleteModal = true;
      this.selectItem = item;
      this.selectIndex = index;
    },
    async getProduct() {
      const { productAll } = ProductStorage();
      this.listData = await productAll();
    },
    getAllProduct() {
      this.loaderTable = true;
      const { getProductAll } = ProductServices();
      const { productAdd } = ProductStorage();
      getProductAll().then((resp) => {
        const response = resp.data;
        if (response.code == 200) {
          response.data.map((item) => {
            productAdd(item);
          });
          this.getProduct();
        }
        this.loaderTable = false;
      });
    },
    firstRowData(data) {
      const { productAdd } = ProductStorage();
      productAdd(data);
      this.getProduct();
    },
    btnDelete() {
      const { deleteProductId } = ProductServices();
      const { productDeleteById } = ProductStorage();
      const index = this.selectIndex;
      this.loaderDelete = { [index]: true };

      deleteProductId(this.selectItem.id).then((resp) => {
        const response = resp.data;
        if (response.code == 202) {
          productDeleteById(this.selectItem.id);
          this.getProduct();
          this.$toast.success("El registro ha sido eliminado exitosamente");
        }
        this.loaderDelete = { [index]: false };
      });
    },
    toggleStatus(list) {
      const { updateProduct } = ProductServices();
      updateProduct(list.id, list).then((resp) => {
        const response = resp.data;
        if (response.code == 201) {
          this.firstRowData(response.data);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.product {
  .profile {
    width: 60px;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    margin: 10px 0px;
    border: solid 1px #d8d8d8;
  }

  .switch {
    display: grid;
    justify-content: center;
  }
}
</style>
