<template>
  <v-dialog
    v-model="viewModal"
    persistent
    scrollable
    :max-width="maxWidth"
    content-class="dialog"
  >
    <v-card>
      <v-card-title class="text-h5 title">
        {{ title }}
      </v-card-title>
      <v-card-text class="message">{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="btnCloseDialog()"> No </v-btn>
        <v-btn text @click="btnAcceptDialog()"> Si </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CustomDialog",
  data: () => ({
    viewModal: false,
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
      default: "290",
    },
  },
  watch: {
    dialog: function (val) {
      this.viewModal = val;
    },
  },
  methods: {
    btnCloseDialog() {
      this.$emit("update:dialog", false);
      this.viewModal = false;
    },
    btnAcceptDialog() {
      this.$emit("onChange", true);
      this.btnCloseDialog();
    },
  },
};
</script>

<style scoped lang="scss">
.dialog {
  .title {
    margin-top: 15px;
  }

  .message {
    font-size: 14px;
  }
}
</style>
