<template>
  <div>
    <v-dialog
      v-model="viewModal"
      persistent
      max-width="600px"
      scrollable
      content-class="admin-dialog"
    >
      <v-card class="custom-detail">
        <v-card-title class="card-title">
          <span class="headline">Detalles</span>

          <v-spacer></v-spacer>

          <CustomButtonAction
            :btnCancel="true"
            @onChangeCancel="cancel()"
            colorBtnCancel="white"
          />
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <table class="dialog-table-details" v-if="!chart">
            <div v-for="(name, key) in data.label" :key="key">
              <tr v-if="name === 'photo'" colspan="2">
                <td class="img" colspan="2">
                  <img
                    :src="onValue(name, item[name]) | urlImage"
                    alt="profile"
                  />
                </td>
              </tr>
              <tr v-if="name !== 'photo'">
                <td class="margen-company width">
                  <b>{{ onLabel(name) }}:</b>
                </td>
                <td>
                  {{ onValue(name, item[name]) }}
                </td>
              </tr>
            </div>
          </table>

          <div v-if="chart">
            <div style="padding: 16px">
              <pie-chart
                :chartData="item.data"
                :chartLabels="item.labels"
              ></pie-chart>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as moment from "moment";
import useLabel from "./useLabel";
import CustomButtonAction from "../custom-button-action/CustomButtonAction.vue";
import PieChart from "../custom-chart/PieChart.vue";

export default {
  name: "CustomDetail",
  data: () => ({
    viewModal: false,
    data: {
      label: {},
      value: {},
    },
  }),
  components: {
    CustomButtonAction,
    PieChart,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    item: {},
    chart: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialog: function (val) {
      this.viewModal = val;
    },
  },
  mounted() {
    this.data = useLabel[this.type];
  },
  methods: {
    cancel() {
      this.viewModal = false;
      this.$emit("update:dialog", false);
    },
    onLabel(name) {
      const itemValue = this.data.value;
      return itemValue[name];
    },
    onValue(key, value) {
      if (value) {
        switch (key) {
          case "dni":
            return this.filterUnitMile(value);
          case "createdAt":
            return moment(value).format("DD/MM/YYYY");
          case "segment":
            return `${value.name}`;
          case "seller":
            return `${value.name} ${value.lastName}`;
          case "mark":
            return `${value.name}`;
          case "price":
            return `${value} $`;
          default:
            return value;
        }
      }
    },
    filterUnitMile(value) {
      let mil = value.replace(/\./g, "");
      if (!isNaN(mil)) {
        mil = mil
          .toString()
          .split("")
          .reverse()
          .join("")
          .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
        /* eslint-disable */
        mil = mil.split("").reverse().join("").replace(/^[\.]/, "");
      }
      return mil;
    },
  },
};
</script>

<style lang="scss">
.custom-detail {
  .dialog-table-details {
    width: 100%;
    margin-top: 15px;
  }

  .dialog-table-details tr td {
    padding: 5px 0px;
    font-size: 14px;

    b {
      color: var(--color-dark) !important;
    }
  }

  .dialog-table-details tr .width {
    width: 210px;
  }

  .img {
    img {
      width: 150px;
      border-radius: 8px;
      margin: 10px 0px;
      border: solid 1px #d8d8d8;
    }
  }
}
</style>
