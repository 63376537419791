const useColumns = [
  {
    text: "Imagen",
    align: "start",
    value: "photo",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Código",
    align: "start",
    value: "code",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Nombre",
    align: "start",
    value: "name",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Marca",
    align: "center",
    value: "mark",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Cantidad",
    align: "center",
    value: "stock",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Precio $",
    align: "center",
    value: "price",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Disponible",
    align: "center",
    value: "available",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Acción",
    value: "actions",
    sortable: false,
    class: "header-title",
  },
];

export default useColumns;
